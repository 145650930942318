@font-face {
    font-family: 'museosans';
    src: url('../media/font/exljbris_-_museosans-500-webfont.woff2') format('woff2'),
         url('../media/font/exljbris_-_museosans-500-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'museosans';
    src: url('../media/font/exljbris_-_museosans-700-webfont.woff2') format('woff2'),
         url('../media/font/exljbris_-_museosans-700-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

*,
*::before,
*::after {
    box-sizing: border-box;
}

[v-cloak] {
    display: none !important;
}

html,
body {
    font-size: 100%;
    font-style: normal;
    font-weight: 300;
    background-color: #fff;
    color: #000;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#__nuxt,
#__layout,
.page-container,
.main-container,
.swiper {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.main-container {
    height: 848px;
    // margin-top: 116px;
}

.main-logo {
    position: fixed;
    right: 40px;
    bottom: 30px;
    width: 260px;
    height: auto;
    z-index: 100;
}

.top-button.block {
    position: fixed;
    right: 40px;
    top: 30px;
    z-index: 100;

    img {
        position: relative;
    }
}

.top-menu {
    position: fixed;
    top: 39px;
    left: 83px;
    display: flex;
    gap: 0 52px;
    
}

.top-menu__item {
    font-family: 'museosans';
    font-weight: 500;
    font-size: 24px;
    line-height: 150%; /* 36px */
    letter-spacing: -0.456px;
    color: #263B47;
    cursor: pointer;
    user-select: none;

    &.active {
        color: #007DC5;
        font-weight: 700;
    }
}

.top-switch {
    position: fixed;
    right: 365px;
    top: 40px;
    z-index: 100;
}

.block {
    position: relative;

    img {
        max-width: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
}

.animate-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 150;
    transform-origin: center center;
    pointer-events: none;
    opacity: 0;
}

.qf-card {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0072bd;
}

.qf-card.qf-front {
    position: relative;
    background: #0072bd;
}
