.slide-new-features {
    display: flex;
    width: 1696px;
}

.slide-new-features__left {
    width: 1272px;
    height: 848px;
    position: relative;
}

.slide-new-features__right {
    width: 424px;
    height: 848px;
    display: flex;
    flex-direction: column;
}

.slide-new-features__product2-block,
.slide-new-features__product3-block {
    width: 424px;
    height: 424px;
}