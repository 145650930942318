.slide4 {
    display: flex;
    width: 1696px;
}

.slide4__left {
    width: 1272px;
    height: 848px;
    position: relative;
}

.slide4__right {
    width: 424px;
    height: 848px;
    display: flex;
    flex-direction: column;
}

.slide4__person-block,
.slide4__discover-block {
    width: 424px;
    height: 424px;
}