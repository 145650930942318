.presentation-selector__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.presentation-selector__logo {
    width: 400px;
    height: auto;
    margin-bottom: 80px;
}

.presentation-selector {
    display: flex;
}

.presentation-selector__block {
    display: block;
    width: 500px;
    height: 500px;
    background-color: blue;

    &:nth-child(1) {
        margin-right: 60px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
