.slide3 {
    display: flex;
    width: 1696px;
}

.slide3__left {
    width: 1272px;
    height: 848px;
    position: relative;
}

.slide3__right {
    width: 424px;
    height: 848px;
    display: flex;
    flex-direction: column;
}

.slide3__product2-block,
.slide3__product3-block {
    width: 424px;
    height: 424px;
}

.slide3--extra {
    width: 1696px;
}