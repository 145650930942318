@import '~normalize.css/normalize';
@import 'base/reset';

@import 'plugins/_swiper';
@import 'plugins/_plyr';

@import 'base/_base';
@import 'base/_popup';
@import 'base/_screensaver';
@import 'base/_selector';

@import 'slides/_slide0';
@import 'slides/_slide1';
@import 'slides/_slide2';
@import 'slides/_slide3';
@import 'slides/_slide4';
@import 'slides/_slide5';
@import 'slides/_slide6';
@import 'slides/_slide-new-features';

#app {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}
