.slide5 {
    display: flex;
    width: 1696px;
}

.slide5__left {
    height: 848px;
    width: 424px;
    position: relative;
}

.slide5__right {
    height: 848px;
    width: 1272px;
}