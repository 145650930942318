.popup {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // opacity: 0;

    .close-button {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 101px;
        height: 101px;
        z-index: 100;
        cursor: pointer;

        .fill {
            fill: #ffffff;
        }

        .stroke {
            stroke: #ffffff;
        }
    }

    .swiper-wrapper {
        transform-style: preserve-3d;
    }

    .swiper-slide {
        width: 1920px;
        height: 1080px;
        backface-visibility: hidden;
        transform: translate3d(0,0,0);

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .swiper-slide--switch img {
        position: absolute;
        top: 0;
        left: 0;
        display: none;

        &.active {
            display: block;
        }
    }

    .swiper-slide-switch {
        position: absolute;
        top: 95px;
        right: 492px;
        width: 380px;
        height: 50px;
        cursor: pointer;
        z-index: 1;
    }
}

.popup__content {
    opacity: 0;
}

.popup.dark {
    .close-button {
        fill: #ffffff;
        
        .fill {
            fill: #007DC5;
        }

        .stroke {
            stroke: #007DC5;
        }
    }

    // for popup in popup
    .popup.light {
        .close-button {
            fill: transparent;
            
            .fill {
                fill: #fff;
            }
    
            .stroke {
                stroke: #fff;
            }
        }
    }
}

.popup__navigation {
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 201px;
    height: 100px;
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
    z-index: 200;

    &::after {
        content: '';
        position: absolute;
        left: 100px;
        top: 10px;
        height: 80px;
        width: 1px;
        background-color: #007DC5;
    }
}

.popup__navigation-prev,
.popup__navigation-next {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.popup__navigation-next {
    right: 0;
    left: auto;
}

.next,
.prev{
    fill: #007DC5;
}

.popup.left {
    .popup__navigation {
        left: 50px;
    }
}

.popup.dark {
    .popup__navigation {
        background: linear-gradient(181deg, #4F82D5 -1.8%, #4797D4 38.11%, #0B81CF 93.98%);

        &::after {
            background-color: #ffffff;
        }
    }

    .next,
    .prev{
        fill: #fff;
    }
}

.popup__iframe {
    width: 1280px;
    height: 720px;
    display: block;
    position: absolute;
    top: 228px;
    right: 0;
    border: none;
}

.popup__image--iframe-click {
    position: absolute;
    top: 745px;
    left: 165px;
    width: 175px;
    height: 60px;
}

.popup__image--iframe-back {
    position: absolute;
    top: 678px;
    left: 100px;
    width: 160px;
    height: 60px;
}