.slide2 {
    display: flex;
    width: 1696px;
}

.slide2__left {
    width: 1272px;
    height: 848px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #000;
}

.slide2__video {
    width: 100%;
    height: auto;
}

.slide2__right {
    width: 424px;
    height: 848px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slide2__connect-block,
.slide2__remote-block {
    width: 424px;
    height: 374px;
}

.slide2__demo-block {
    width: 424px;
    height: 100px;
}