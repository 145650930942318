.slide6 {
    display: flex;
    width: 1696px;
    position: relative;
}

.button-slide6.block {
    position: absolute;
    bottom: 207px;
    right: 160px;
    cursor: pointer;
    z-index: 100;

    img {
        position: relative;
    }
}