@import '~plyr/src/sass/plyr';

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
    color: #fff !important;
}
.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
.plyr--video .plyr__controls .plyr__control:hover,
.plyr--video .plyr__controls .plyr__control[aria-expanded='true'] {
    background-color: #fff;
}
.plyr.plyr--stopped .plyr__controls {
    display: none;
}
.plyr.plyr--paused .plyr__controls {
    display: flex;
}
.plyr__control--overlaid {
    color: #fff !important;
    transform: translate(-50%, -50%) scale(2.5);
}
.plyr--paused.plyr--full-ui.plyr--video .plyr__control--overlaid,
.plyr--stopped.plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: none !important;
}
.plyr--captions-enabled [data-plyr='captions'] {
    display: none !important;
}
.plyr--full-ui input[type='range'] {
    color: #fff;
}