.slide0 {
    height: 848px;
    width: 1675px;
}

.slide0__start-block {
    height: 100%;
    aspect-ratio: 1696/848;
}

.click-block-marine-1,
.click-block-marine-2,
.click-block-marine-3,
.click-block-marine-4,
.click-block-offgrid-1,
.click-block-offgrid-2,
.click-block-offgrid-3,
.click-block-offgrid-4 {
    position: absolute;
    bottom: 35px;
    left: 175px;
    // background-color: rgba(0,0,0,0.6);
    width: 245px;
    height: 240px;
}

.click-block-marine-2,
.click-block-offgrid-2 {
    left: 520px;
    width: 370px;
}

.click-block-marine-3,
.click-block-offgrid-3 {
    left: 920px;
}

.click-block-marine-4,
.click-block-offgrid-4 {
    left: 1260px;
}
