.slide1 {
    display: flex;
    width: 1696px;
}

.slide1__left,
.slide1__right {
    display: flex;
    flex-direction: column;
    height: 848px;
    width: 848px;
}

.slide1__left {
    width: 424px;
}

.slide1__block-top,
.slide1__block-bottom {
    display: flex;
    height: 424px;
}

.slide1__backup-block,
.slide1__energy-block {
    height: 424px;
    width: 424px;
}

.slide1__hybrid-generators {
    height: 424px;
    width: 848px;
}

// Marine
.slide1--marine {
    width: 1272px;
}

.slide1-marine__intro {
    width: 428px;
    height: 848px;
}

.slide1-marine__left,
.slide1-marine__right {
    display: flex;
    flex-direction: column;
    height: 848px;
    width: 424px;
}

.slide1-marine__small-boat-block,
.slide1-marine__sailing-yacht-block,
.slide1-marine__motor-yacht-block,
.slide1-marine__large-yacht-block {
    height: 424px;
    width: 424px;
}

.slide1-extra--marine {
    display: flex;
    width: 1696px;
    height: 848px;
}

.slide1-extra-marine__intro {
    height: 848px;
    width: 848px;
}

.slide1-extra-marine__left,
.slide1-extra-marine__right {
    display: flex;
    flex-direction: column;
    height: 848px;
    width: 424px;
}

.slide1-extra-marine__catamaran-block,
.slide1-extra-marine__yacht-block,
.slide1-extra-marine__split-phase-block, 
.slide1-extra-marine__narrowboat-block {
    height: 424px;
    width: 424px;
}